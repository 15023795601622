<template>
  <div class="regsit">
    <left-img :target="'/regist'"></left-img>
    <el-form label-width="80px" :model="regist_form" :rules="regist_rules" ref="form_ref">
      <div class="regist_header">警官认证</div>
      <el-form-item label="真实姓名" prop="policename">
        <el-input id="bgColor" placeholder="请输入姓名" v-model="regist_form.policename"></el-input>
      </el-form-item>
      <el-form-item label="证件上传" ref="form_ref" prop="imageUrl" style="margin-top:-40px;" class="avatar_uploader">
        <div class="upload_img">
          <!-- 正面 -->
          <div v-if="disabled == true" class="isDisabled"><img :src="BaseUrl + url1" alt=""></div>
          <el-upload
            v-else
            class="avatar-uploader position"
            :action="BaseUrl1"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :headers="headers"
            :disabled="disabled"
          >
            <img v-if="positive" :src="positive" class="avatar" />
            <img src="../../public/images/jingguanzheng2.png" v-else class="avatar-uploader-icon" />
          </el-upload>
          <!-- 反面 -->
          <el-upload
            class="avatar-uploader position"
            :action="BaseUrl2"
            :on-success="handleAvatarSuccessY"
            :before-upload="beforeAvatarUploadY"
            :on-change="onchangeY"
            :on-remove="onremoveY"
            :headers="headers"
            :auto-upload="false"
            ref="upload_img"
            :data="regist_form"
          >
            <img v-if="backs" :src="backs" class="avatar" />
            <img src="../../public/images/jingguanzheng1.png" v-else class="avatar-uploader-icon" />
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="联系电话" prop="contacts">
        <el-input id="bgColor" placeholder="请输入联系电话" v-model="regist_form.contacts"></el-input>
      </el-form-item>
      <el-form-item label="公司单位" prop="company">
        <el-input id="bgColor" placeholder="请输入公司单位" v-model="regist_form.company"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="regist()">立即注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import leftImg from "./left_img.vue";
// 手机号的正则
// var ph = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
export default {
  data() {
    return {
		BaseUrl1:this.$store.state.BaseUrl+"admin/j_info_upload",
		BaseUrl2:this.$store.state.BaseUrl+"admin/admin/j_info_reg",
      // 图片绑定
      // 绑定
      regist_form: {
        //真实姓名
        policename: "",
        contacts: "",
        // 真实名字
        company: "",
        jq_user_jid: ""
      },
      positive: "",
      backs: "",
      // 上传图片
      chenge_img: 0,
      BaseUrl: this.$store.state.BaseUrl,
      url1: "",
      disabled: false,
      // 验证规则
      regist_rules: {
        policename: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur"
          }
        ],

        contacts: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号格式不对",
            trigger: "blur"
          }
        ],
        company: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
          {
            min: 5,
            max: 20,
            message: "长度在 5 到 50 个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: {
    leftImg
  },
  computed: {
    headers() {
      return {
        Authorization:
          this.random() + window.localStorage.getItem("user_id") + this.random()
      };
    }
  },
  mounted() {
    this.$axios
      .post("/j_info_select", {
        user_id: window.localStorage.getItem("user_id")
      })
      .then(res => {
        if (res.code == 200) {
          this.url1 = res.data.img;
          this.chenge_img++;
          this.disabled = true;
        }
      });
  },
  methods: {
    //生成随机数
    random() {
      return Math.random()
        .toString()
        .slice(-4);
    },
    //   正面图片判断
    handleAvatarSuccess(res, file) {
      if (res.code == 201) {
        this.chenge_img++;
        this.positive = URL.createObjectURL(file.raw);
        this.$message({
          type: "success",
          message: res.msg
        });
      } else {
        this.$message({
          type: "error",
          message: res.msg
        });
      }
    },
    //   正面图片判断
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //   反面图片判断
    handleAvatarSuccessY(res, file) {
      if (res.code == 201) {
        this.chenge_img++;

        this.$message({
          type: "success",
          message: res.msg
        });
        this.$router.push("/login");
      } else {
        this.$message({
          type: "error",
          message: res.msg
        });
      }
    },
    //   反面图片判断
    beforeAvatarUploadY(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //反面上传图片时
    onchangeY(file) {
      this.backs = URL.createObjectURL(file.raw);
    },
    //取消上传的图片时
    onremoveY() {
      this.backs = "";
    },

    // 正面上传图片
    positive_submit_image(param) {
      let formDate = new FormData();
      formDate.append("file", param.file);
    },
    // 注册
    regist() {
      this.regist_form.jq_user_jid = window.localStorage.getItem("user_id");

      if (this.chenge_img < 1) {
        this.$message({
          type: "error",
          message: "图片不能为空"
        });
      } else {
        this.$refs.form_ref.validate(valid => {
          if (valid) {
            this.$refs.upload_img.submit();
          } else {
            this.$message({
              message: "请填写完整",
              type: "warning"
            });
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.position {
  width: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  height: 138px;
  line-height: 138px;
  display: inline-block;
  text-align: center;
  background-color: #f0f0f0;
  width: 100%;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
.avatar-uploader {
  margin-top: 15px;
}
.isDisabled {
  width: 100%;
}
.isDisabled img {
  width: 100%;
  height: 138px;
}
.el-form {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-10%);
  width: 450px;
  padding: 50px 20px;
  border-radius: 10px;
  background-color: #fff;
}
#bgColor:focus {
  border: 2px solid rgb(40, 56, 82);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(40, 56, 82, 0.15);
}
.regist_header {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.el-input,
.upload_img {
  width: 100%;
}
@media screen and (max-width: 1240px) {
  .el-form {
    left: 38%;
  }
}
@media screen and (max-width: 550px) {
  .el-form {
    width: 80%;
    margin-bottom: 50px;
    top: 0;
    left: 12%;
  }
  .avatar-uploader-icon {
    width: 80%;
  }
}
</style>